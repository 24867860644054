import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouterState } from 'src/app/core/store/selectors/router.selectors';
import { Permission, Role } from 'src/app/roles/types/role.interface';
import { User } from 'src/app/users/types/user.interface';
import { SsoLoginUrl } from '../../types/sso-login-url.interface';
import { AuthenticationState } from '../reducers/authentication.reducers';

/**
 * Authentication state selelctor
 */
export const getAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');

/**
 * logged user selector
 */
export const getLoggedUser = createSelector(getAuthenticationState, (state: AuthenticationState) => state?.user);

/**
 * userLoggedIn flag selector
 */
export const getUserLoggedIn = createSelector(getAuthenticationState, (state: AuthenticationState) => state?.userLoggedIn);

/**
 * userLoggingIn flag selector
 */
export const getUserLoggingIn = createSelector(getAuthenticationState, (state: AuthenticationState) => state?.userLoggingIn);

/**
 * login error selector
 */
export const getLoginError = createSelector(getAuthenticationState, (state: AuthenticationState) => state?.loginError);

/**
 * login error message selector
 */
export const getLoginErrorMessage = createSelector(getLoginError, (loginError: Error) => loginError?.message);

/**
 * logged user's role selector
 */
export const getUserRole = createSelector(getLoggedUser, (user: User) => user?.role);

/**
 * logged user's permission selector
 */
export const getUserPermissions = createSelector(getUserRole, (role: Role) => role?.permissions || []);

/**
 * logged user's permission's name
 */
export const getUserPermissionNames = createSelector(getUserPermissions, (permissions: Permission[]) =>
    permissions.map(({ name }) => name)
);

/**
 * logged user role name
 */
export const getUserRoleName = createSelector(getUserRole, (role: Role) => role.name);

/**
 * requestResetPasswordSending flag selector
 */
export const getRequestResetPasswordSending = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): boolean => state.requestResetPasswordSending
);

/**
 * request reset password error selector
 */
export const getRequestResetPasswordError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): Error => state.requestResetPasswordError
);

/**
 * resettingPassword flag selector
 */
export const getResettingPassword = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): boolean => state.resettingPassword
);

/**
 * Reset password error selector
 */
export const getResetPasswordError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): Error => state.resetPasswordError
);

/**
 * Reset password token selector
 */
export const getResetPasswordToken = createSelector(getRouterState, (router) => router.state.params.token);

/**
 * Is init password route selector
 */
export const isInitPasswordRoute = createSelector(getRouterState, (router) =>
    router.state.urlWithoutQueryParams.includes('init-password')
);

/** Get sso login url */
export const getSsoLoginUrl = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): SsoLoginUrl => state.ssoLoginUrl
);

/** Get sso login url loading */
export const getSsoLoginUrlLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): boolean => state.ssoLoginUrlLoading
);

/** get authentication redirection url */
export const getAuthenticationRedirectionUrl = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): string => state.authenticationRedirectionUrl
);

/** Get sso login error */
export const getSsoLoginError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState): Error => state.ssoLoginError
);
