import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '', pathMatch: 'full' },
    {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    },
    {
        path: '',
        loadChildren: () => import('./root/root.module').then((m) => m.RootModule),
    },
    {
        path: 'error-pages',
        loadChildren: () => import('./error-pages/error-pages.module').then((m) => m.ErrorPagesModule),
    },
    {
        path: 'pdf',
        loadChildren: () => import('./pdf/pdf.module').then((m) => m.PdfModule),
    },
    { path: '**', redirectTo: 'error-pages/page-not-found' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
