import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { JwtModule } from '@auth0/angular-jwt';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { authenticationReducer } from './authentication/store/reducers/authentication.reducers';
import { tokenGetter } from './authentication/utils/token-getter';
import { CoreModule } from './core/core.module';
import { appRouterReducer } from './core/store/reducers/router.reducers';

registerLocaleData(localeFr);

/**
 * default key for each table storage
 */
const DEFAULT_FILTER_STORAGE_KEY = ['listCriteria', 'tableFilter'];

/**
 * Synchronize a part of logged user infos to local storage for the need of JWT
 * Keep user infos to local storage so we will no loose it on browser refresh
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageUserSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ authentication: ['user', 'userLoggedIn', 'token', 'authenticationRedirectionUrl'] }],
        rehydrate: true, // Allow restoring datas from  storage to store after a browser refresh
        storage: localStorage,
    })(reducer);
}

/**
 *  Synchronize a part of request state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageRequestListSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ requestGlobalMonitoring: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}
/**
 *  Synchronize a part of endToEnd state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageEndToEndListSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ endToEndGlobalMonitoring: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of user list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageUserListSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ user: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of Role list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageRoleListSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ role: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of packaging stock list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStoragePackagingStockSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ packagingStockState: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of worksite list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageWorksiteSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ worksiteMonitoring: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of worksite list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageOperatorSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ operator: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of pointMonitoring list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStoragePointMonitoringSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ pointMonitoring: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of document list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageDocumentSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ document: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 *  Synchronize a part of point control list state to save table settings
 * @param reducer ActionReducer
 * @returns ActionReducer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStoragePointControlSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ pointMonitoringControl: DEFAULT_FILTER_STORAGE_KEY }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 * Request creation state sync to local storage
 */

/**
 * Synchronize a part of packing request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStoragePackingRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ 'packing-request-creation': ['request', 'stockStates', 'listCriteria', 'selectedStockStates'] }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 * Synchronize a part of hm request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageHmRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ 'hm-request-creation': ['request'] }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 * Synchronize a part of stock request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageStockRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ 'stock-request-creation': ['request'] }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

/**
 * Synchronize a part of helios request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageHeliosRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                'helios-request-creation': [
                    'requestCreationPayload',
                    'worksites',
                    'heliosWorksitesCriteria',
                    'listCriteria',
                    'worksiteSupportAvailability',
                ],
            },
        ],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}
/**
 * Synchronize a part of engine request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageEngineRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ 'engine-request-creation': ['requestCreationPayload', 'selectedStockOutputPackages'] }],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        /**
         * appRouterReducer allow us to have actual route datas (params, query params, url, data, ...) available in our store (router store)
         * localStorageUserSyncReducer synchronize a part of logged user infos to local storage
         */
        StoreModule.forRoot(appRouterReducer, {
            metaReducers: [
                localStorageUserSyncReducer,
                localStorageRequestListSyncReducer,
                localStorageEndToEndListSyncReducer,
                localStorageUserListSyncReducer,
                localStorageRoleListSyncReducer,
                localStoragePackagingStockSyncReducer,
                localStorageWorksiteSyncReducer,
                localStorageOperatorSyncReducer,
                localStoragePointMonitoringSyncReducer,
                localStorageDocumentSyncReducer,
                localStoragePointControlSyncReducer,
                localStoragePackingRequestCreationSyncReducer,
                localStorageHmRequestCreationSyncReducer,
                localStorageStockRequestCreationSyncReducer,
                localStorageEngineRequestCreationSyncReducer,
                localStorageHeliosRequestCreationSyncReducer,
            ],
            runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true, connectInZone: true }),
        CoreModule,
        /**
         * Put authentication state in AppModule instead of AuthenticationModule to ensure authentication
         * state rehydratation when we are in another lazzy loaded module and the page is reloaded:
         */
        StoreModule.forFeature('authentication', authenticationReducer),

        /**
         * Every API call to whitelisted domains will automatically have the authorization 'Bearer {token}' in request header.
         * Every API call to blacklisted routes will not have it, this is the case for authentication API route for instance.
         */
        JwtModule.forRoot({
            config: {
                tokenGetter,
                // allowedDomains: ['localhost:3000'],
                allowedDomains: environment.jwtWhitelistedDomains,
                disallowedRoutes: environment.blacklistedRoutes,
            },
        }),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
    ],
})
export class AppModule {}
