import { createAction, props } from '@ngrx/store';
import { RequestPasswordResetDto } from 'src/app/authentication/types/request-password-reset.dto';
import { ResetPasswordDto } from 'src/app/authentication/types/reset-password.dto';
import { User } from 'src/app/users/types/user.interface';
import { AuthenticationResponse } from '../../types/authentication-response.interface';
import { Credentials } from '../../types/credentials.interface';
import { SsoLoginUrl } from '../../types/sso-login-url.interface';

/**
 * Login action
 * props: Credentials (login and password)
 */
export const logIn = createAction('[Authentication] Log In', props<Credentials>());

/**
 * Login fail action
 */
export const logInFail = createAction('[Authentication] Log In Fail', props<Error>());

/**
 * Login action
 *  props: AuthenticationResponse (user and token)
 */
export const logInSuccess = createAction('[Authentication] Log In Success', props<AuthenticationResponse>());

/**
 * Logout action
 */
export const logOut = createAction('[Authentification] Log Out');

/**
 * Clear authentication error message action
 */
export const clearAuthenticationErrorMessage = createAction('[Authentication] Clear error message');

/**
 * Request reset password action
 */
export const requestResetPassword = createAction(
    '[Authentication] Request reset password',
    props<{ payload: RequestPasswordResetDto }>()
);

/**
 * Request password fail action
 */
export const requestResetPasswordFail = createAction('[Authentication] Request reset password failed', props<Error>());

/**
 * Request password success Action
 */
export const requestResetPasswordSuccess = createAction('[Authentication] Request reset password success');

/**
 * Reset password action
 * props: ResetPasswordDto (token and password)
 */
export const resetPassword = createAction('[Authentication] reset password', props<{ payload: ResetPasswordDto }>());

/**
 * Reset password fail
 */
export const resetPasswordFail = createAction('[Authentication] reset password fail', props<Error>());

/**
 * Reste password success
 * props: AuthenticationResponse (token and user)
 */
export const resetPasswordSuccess = createAction(
    '[Authentication] reset password success',
    props<{ payload: AuthenticationResponse }>()
);

/**
 * Check token validty action
 * payload: token
 */
export const checkTokenValidity = createAction('[Authentication] Check token validity', props<{ payload: string }>());

/**
 * Check token validity success action
 */
export const checkTokenValiditySuccess = createAction('[Authentication] Check token validity success');

/**
 * Check token validity fail action
 */
export const checkTokenValidityFail = createAction('[Authentication] Check token validity fail', props<{ payload: Error }>());

/**
 * Update logged user in store
 */
export const updateLoggedUserInStore = createAction('[Authentication] Update Logged User In Store', props<{ payload: User }>());

/** Get url for sso login */
export const getUrlForSsoLogin = createAction('[Authentication] Get url for sso login');

/** Get url for sso login fail */
export const getUrlForSsoLoginFail = createAction('[Authentication] Get url for sso login fail', props<{ payload: Error }>());

/** Get url for sso login success */
export const getUrlForSsoLoginSuccess = createAction(
    '[Authentication] Get url for sso login success',
    props<{ payload: SsoLoginUrl }>()
);

/** Set authentication redirection url */
export const setAuthenticationRedirectionUrl = createAction(
    '[Authentication] Set authentication redirection url',
    props<{ payload: string }>()
);

/** clear authentication redirection url */
export const clearAuthenticationRedirectionUrl = createAction('[Authentication] Clear authentication redirection url');

/** sso login */
export const ssoLogin = createAction('[Authentication] SSO login', props<{ payload: string }>());

/** sso login fail */
export const ssoLoginFail = createAction('[Authentication] SSO login fail', props<Error>());

export const ssoLoginSuccess = createAction('[Authentication] SSO login success');
