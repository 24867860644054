import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/users/types/user.interface';
import { AuthenticationResponse } from '../../types/authentication-response.interface';
import { Credentials } from '../../types/credentials.interface';
import { SsoLoginUrl } from '../../types/sso-login-url.interface';
import {
    clearAuthenticationErrorMessage,
    clearAuthenticationRedirectionUrl,
    getUrlForSsoLogin,
    getUrlForSsoLoginFail,
    getUrlForSsoLoginSuccess,
    logIn,
    logInFail,
    logInSuccess,
    logOut,
    requestResetPassword,
    requestResetPasswordFail,
    requestResetPasswordSuccess,
    resetPassword,
    resetPasswordFail,
    resetPasswordSuccess,
    setAuthenticationRedirectionUrl,
    ssoLogin,
    ssoLoginFail,
    ssoLoginSuccess,
    updateLoggedUserInStore,
} from '../actions/authentication.actions';

/**
 * Authentication state
 */
export interface AuthenticationState {
    user: User;
    userLoggedIn: boolean;
    userLoggingIn: boolean;
    token: string;
    loginError: Error;

    requestResetPasswordSending: boolean;
    requestResetPasswordSent: boolean;
    requestResetPasswordError: Error;

    resettingPassword: boolean;
    resetPasswordError: Error;

    ssoLoginUrl: SsoLoginUrl;
    ssoLoginUrlError: Error;
    ssoLoginUrlLoading: boolean;
    ssoLoginUrlLoaded: boolean;

    authenticationRedirectionUrl: string;
    ssoLoginError: Error;
}

/**
 * Initial authentication state value
 */
const initialState: AuthenticationState = {
    user: null,
    userLoggedIn: false,
    userLoggingIn: false,
    token: null,
    loginError: null,
    ssoLoginError: null,
    requestResetPasswordError: undefined,
    requestResetPasswordSending: false,
    requestResetPasswordSent: false,
    resetPasswordError: undefined,
    resettingPassword: false,
    ssoLoginUrl: undefined,
    ssoLoginUrlError: undefined,
    ssoLoginUrlLoaded: false,
    ssoLoginUrlLoading: false,
    authenticationRedirectionUrl: '',
};

/**
 *  Login reducer
 * @param state AuthenticationState
 * @param props Credentials object containing login and password
 * @returns AuthenticationState up to date
 */
const logInReducer = (state: AuthenticationState, props: Credentials): AuthenticationState => ({
    ...state,
    userLoggedIn: false,
    userLoggingIn: true,
    loginError: undefined,
    ssoLoginError: undefined,
});

/**
 *  Login fail reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const logInFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    userLoggedIn: false,
    userLoggingIn: false,
    loginError: props,
});

/**
 *  Login success reducer
 * @param state AuthenticationState
 * @param props AuthenticationResponse object contaning logged user and token
 * @returns AuthenticationState with updated user and token
 */
const logInSuccessReducer = (state: AuthenticationState, props: AuthenticationResponse): AuthenticationState => ({
    ...state,
    userLoggedIn: true,
    userLoggingIn: false,
    user: props.user,
    token: props.token,
    loginError: undefined,
});

/**
 * sso login reducer
 * @param state authentication state
 * @returns updated authentication state
 */
const ssoLoginReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    ssoLoginError: undefined,
    loginError: undefined,
});

/**
 * sso login fail reducer
 * @param state authentication state
 * @param props error
 * @returns updated authentication state
 */
const ssoLoginFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    ssoLoginError: props,
    loginError: undefined,
});

/**
 * sso login success reducer
 * @param state authentication state
 * @returns udated authentication state
 */
const ssoLoginSuccessReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    ssoLoginError: undefined,
    loginError: undefined,
});

/**
 *  Logout reducer
 * @param state AuthenticationState
 * @returns AuthenticationState with token and user cleared and userLoggedIn flag set to false
 */
const logOutReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    user: undefined,
    userLoggedIn: false,
    token: undefined,
});

/**
 *  Request reset password reducer
 * @param state AuthenticationState
 * @returns AuthenticationState up to date
 */
const requestResetPasswordReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    requestResetPasswordSent: false,
    requestResetPasswordSending: true,
    requestResetPasswordError: undefined,
});

/**
 *  Request password fail reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const requestResetPasswordFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    requestResetPasswordError: props,
    requestResetPasswordSent: false,
    requestResetPasswordSending: false,
});

/**
 *  Request password success reducer
 * @param state AuthenticationState
 * @returns AuthenticationState up to date
 */
const requestResetPasswordSuccessReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    requestResetPasswordError: undefined,
    requestResetPasswordSent: true,
    requestResetPasswordSending: false,
});

/**
 *  Reset password reducer
 * @param state AuthenticationState
 * @returns AuthenticationState up to date
 */
const resetPasswordReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    resettingPassword: true,
    resetPasswordError: undefined,
});

/**
 *  Reset password fail reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const resetPasswordFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    resettingPassword: false,
    resetPasswordError: props,
});

/**
 *  Reset password success reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const resetPasswordSuccessReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    resettingPassword: false,
    resetPasswordError: undefined,
});

/**
 * Clear authentication error message
 * @param state Authentication State
 * @returns updated authentication state
 */
const clearAuthenticationErrorMessageReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    loginError: undefined,
});

/**
 *  Update Logged User when edit profile is success
 * @param state AuthenticationState
 * @param props User
 * @returns AuthenticationState up to date
 */
const updateLoggedUserInStoreReducer = (state: AuthenticationState, props: { payload: User }): AuthenticationState => {
    const { role, ...user } = props.payload;
    return {
        ...state,
        user: { ...state.user, ...user },
    };
};

/**
 * Get url for sso login reducer
 * @param state AuthenticationState
 * @returns AuthenticationState up to date
 */
const getUrlForSsoLoginReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    ssoLoginUrl: undefined,
    ssoLoginUrlError: undefined,
    ssoLoginUrlLoaded: false,
    ssoLoginUrlLoading: true,
});

/**
 * Get url for sso login fail reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const getUrlForSsoLoginFailReducer = (state: AuthenticationState, props: { payload: Error }): AuthenticationState => ({
    ...state,
    ssoLoginUrl: undefined,
    ssoLoginUrlError: props.payload,
    ssoLoginUrlLoaded: false,
    ssoLoginUrlLoading: false,
});

/**
 * Get url for sso login success reducer
 * @param state AuthenticationState
 * @param props Error
 * @returns AuthenticationState up to date
 */
const getUrlForSsoLoginSuccessReducer = (state: AuthenticationState, props: { payload: SsoLoginUrl }): AuthenticationState => ({
    ...state,
    ssoLoginUrl: props.payload,
    ssoLoginUrlError: undefined,
    ssoLoginUrlLoaded: false,
    ssoLoginUrlLoading: false,
});

/** set authentication redirection url reducer */
const setAuthenticationRedirectionUrlReducer = (state: AuthenticationState, props: { payload: string }): AuthenticationState => ({
    ...state,
    authenticationRedirectionUrl: props.payload,
});

/** clear authentication redirection url reducer */
const clearAuthenticationRedirectionUrlReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    authenticationRedirectionUrl: '',
});

/**
 * Authentication reducer creator
 */
const reducer = createReducer(
    initialState,
    on(logIn, logInReducer),
    on(logInFail, logInFailReducer),
    on(logInSuccess, logInSuccessReducer),
    on(logOut, logOutReducer),
    on(requestResetPassword, requestResetPasswordReducer),
    on(requestResetPasswordFail, requestResetPasswordFailReducer),
    on(requestResetPasswordSuccess, requestResetPasswordSuccessReducer),
    on(resetPassword, resetPasswordReducer),
    on(resetPasswordFail, resetPasswordFailReducer),
    on(resetPasswordSuccess, resetPasswordSuccessReducer),
    on(clearAuthenticationErrorMessage, clearAuthenticationErrorMessageReducer),
    on(updateLoggedUserInStore, updateLoggedUserInStoreReducer),
    on(getUrlForSsoLogin, getUrlForSsoLoginReducer),
    on(getUrlForSsoLoginFail, getUrlForSsoLoginFailReducer),
    on(getUrlForSsoLoginSuccess, getUrlForSsoLoginSuccessReducer),
    on(setAuthenticationRedirectionUrl, setAuthenticationRedirectionUrlReducer),
    on(clearAuthenticationRedirectionUrl, clearAuthenticationRedirectionUrlReducer),
    on(ssoLogin, ssoLoginReducer),
    on(ssoLoginFail, ssoLoginFailReducer),
    on(ssoLoginSuccess, ssoLoginSuccessReducer)
);

/**
 * Authentication reducer
 * @param state AuthenticationState
 * @param action authentication actions
 * @returns ActionReducer
 */
export function authenticationReducer(state: AuthenticationState | undefined, action: Action) {
    return reducer(state, action);
}
