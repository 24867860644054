import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppRouterState } from '../reducers/router.reducers';

/**
 * Router state selector
 */
export const getRouterState = createFeatureSelector<RouterReducerState<AppRouterState>>('router');

/**
 * Actual route URL selector
 */
export const getUrl = createSelector(getRouterState, (router) => router?.state.url || '');

/**
 * Actual route query params selector
 */
export const geQueryParams = createSelector(getRouterState, (router) => router?.state.queryParams || {});

/**
 * browser refresh selector: return true if the browser was refreshed
 */
export const getIsBrowserRefresh = createSelector(getRouterState, (router) => !!(router && router.navigationId === 1));

/**
 * Page title selector
 */
export const getTitle = createSelector(
    getRouterState,
    (router) => router && router.state && router.state.data && router.state.data.title
);
